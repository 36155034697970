import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from './component/menu/Menus'
import Home from './component/home/Home'
import Profile from './component/profile/Profile'
import Contact from './component/contact/Contact'
 
function App() {
  return (
    <Router >
      <div className="app-header">
        <Menu />
      </div>
      <div className="app-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}
 
export default App;