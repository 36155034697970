import React, { Component } from 'react';
import './Contact.css';
 
class Contact extends Component {
    render() {
        return (
            <div className="card">
                <div className="container">
                    <div className="Titel">
                       

                    </div>
                    <div className="conten">

                    </div>
                </div>
            </div>
        );
    }
}
 
export default Contact;