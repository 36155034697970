import React, { Component } from 'react';
import './Home.css';
 
class Home extends Component {
    render() {
        return (
            <div className="card">
                <div className="container">
                    <div className="Titel">
                        Home
                    </div>
                    <div className="conten">
                        Selamat Datang
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Home;