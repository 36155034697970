import './Profile.css';
import React, { Component, useState, useEffect } from 'react';
import { Scatter, Line } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import Chart from "chart.js/auto";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAlignCenter, faTrash, faVrCardboard } from '@fortawesome/free-solid-svg-icons';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
 
const Profile = () => {


  
    var innerdiameter = 0;
    var outerperimeterval = 0;
    var cuperqu = 0;
    var cuperqulist = [];
    var alpha = 0;
    var ofl = 0;
    var deptsebelumnya = 0;
    var cumofl = 0;
    var pi = 3.141592654;
    var counter = 0;
    var enbe = [];
    var sby = [];
    var sby3 = 0;
    var sbx = [];
    var sbxultcomp = [];
    var sbxultcomp2 = [];
    var sbxultpullout = [];
    var sbxultpullout2 = [];
    var sbxultpullout3 = [];
    var enber = 0;
    var quvalue = 0;
    var frilo = 0;
    var frilosebelum = 0;
    var wepe = 0;
    var qipu = 0;
    var qacom = 0;
    var qpull = 0;
    var cate = '';
    var ysat = 0;
    var yak = 0;
    var ckn = 0;
    var okn = 0;
    var kvalue = 0;
    var e50 = 0;
    var my = 0;
    var ppn = 0;
    var bgcolor = '';
    var counterpdf = 0;
  
    const [chartData, setChartData] = useState({
      datasets: [],
    });
    const [formData, setFormData] = useState([{ x: '', st: '', y: '', cd: '', qu: '', em: '', al: ''  }]);
    const [datasetLabel, setDatasetLabel] = useState('Data');
    const [inputValue1, setInputValue1] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const [unitweight, setunitweight] = useState('');
    const [concretestrength, setconcretestrength] = useState('');
    const [atmosphere, setatsmosphere] = useState('');
    const [product, setProduct] = useState('');
    const [outerperimeter, setouterperimeter] = useState('');
    const [innerperimeter, setinnerperimeter] = useState('');
    const [areaout, setareaout] = useState('');
    const [areapile, setareapile] = useState('');
    const [undrshearstrength, setundrshearstrength] = useState('');
    const [allowqa, setallowqa] = useState('');
    const [allowqpa, setallowqpa] = useState('');
    const [poisson, setpoisson] = useState('');
    const [panjangtiang, setpanjangtiang] = useState('');
    const [kedalamangalian, setkedalamangalian] = useState('');
  
    
  
    
  
    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
        const dataset = data.map((row) => ({ x: row[3], y: row[0] }));
  
  
  
        const formDataFromExcel = data.map((row) => ({ 
          x: row[3], 
          st: row[1],
          y: row[0], 
          cd: row[2], 
          qu: row[2] === 'R' || row[2] === 'r' ? 1 + (((row[3] / 16)/(1-(row[3] / 130))) * 1000) : 0,
          em: row[2] === 'R' || row[2] === 'r' ? row[3] <=50 ? (row[3] * 0.003) - 0.0039 : row[3] > 50 && row[3] < 70 ? 0.0275 * row[3] - 1.225 : row[3] * 0.01:0,
          al: row[2] === 'R' || row[2] === 'r' ? 
          row[3] <=50 ? ((row[3] * 0.003) - 0.0039) < 0.05 || ((row[3] * 0.003) - 0.0039) > 1 ? 0 : ((row[3] * 0.003) - 0.0039) >= 0.05 && ((row[3] * 0.003) - 0.0039) < 0.1 ? 2 * ((row[3] * 0.003) - 0.0039) + 0.35 : ((row[3] * 0.003) - 0.0039) >= 0.1 && ((row[3] * 0.003) - 0.0039) < 0.3 ? 0.75*((row[3] * 0.003) - 0.0039) + 0.475 : ((row[3] * 0.003) - 0.0039) >= 0.3 && ((row[3] * 0.003) - 0.0039) < 0.5 ? 0.5*((row[3] * 0.003) - 0.0039)+0.475 : 0.5*((row[3] * 0.003) - 0.0039)+0.55 :
          row[3] > 50 && row[3] < 70 ? (0.0275 * row[3] - 1.225) < 0.05 || (0.0275 * row[3] - 1.225) > 1 ? 0 : (0.0275 * row[3] - 1.225) >= 0.05 && (0.0275 * row[3] - 1.225) < 0.1 ? 2 * (0.0275 * row[3] - 1.225) + 0.35 : (0.0275 * row[3] - 1.225) >= 0.1 && (0.0275 * row[3] - 1.225) < 0.3 ? 0.75*(0.0275 * row[3] - 1.225) + 0.475 : (0.0275 * row[3] - 1.225) >= 0.3 && (0.0275 * row[3] - 1.225) < 0.5 ? 0.5*(0.0275 * row[3] - 1.225)+0.475 : 0.5*(0.0275 * row[3] - 1.225)+0.55 :   
          (row[3] * 0.01) < 0.05 || (row[3] * 0.01) > 1 ? 0 : (row[3] * 0.01) >= 0.05 && (row[3] * 0.01) < 0.1 ? 2 * (row[3] * 0.01) + 0.35 : (row[3] * 0.01) >= 0.1 && (row[3] * 0.01) < 0.3 ? 0.75*(row[3] * 0.01) + 0.475 : (row[3] * 0.01) >= 0.3 && (row[3] * 0.01) < 0.5 ? 0.5*(row[3] * 0.01)+0.475 : 0.5*(row[3] * 0.01)+0.55 :   
          0,
        }));
  
        setChartData({
          datasets: [
            {
              label: 'Data dari Excel',
              data: dataset,
              backgroundColor: 'rgba(75,192,192,0.6)',
            },
          ],
        });
  
        setFormData(formDataFromExcel);
      };
      reader.readAsBinaryString(file);
      
    };
  
    const handleInputChange = (index, event) => {
      const values = [...formData];
      values[index][event.target.name] = event.target.value;
      if (values[index]['cd'] === 'R' || values[index]['cd'] === 'r') {
        values[index]['qu'] = 1 + (((values[index]['x'] / 16)/(1-(values[index]['x'] / 130))) * 1000);
  
      } else {values[index]['qu'] = 0}
      if (values[index]['cd'] === 'R' || values[index]['cd'] === 'r') {
          if (values[index]['x']  <= 50){
              values[index]['em'] = (values[index]['x'] * 0.003) - 0.0039;
          }
          if (values[index]['x']  > 50 && values[index]['x'] < 70){
              values[index]['em'] = 0.0275* values[index]['x'] - 1.225;
          }
          if (values[index]['x']  >= 70){
              values[index]['em'] = values[index]['x'] * 0.01;
          }
  
    
        } else {values[index]['em'] = 0}
  
  
      setFormData(values);
    };
  
    const handleAddRow = () => {
      setFormData([...formData, { x: '', st: '', y: '', cd: '', qu: '', em: '', al: ''}]);
    };
  
    const handleDeleteRow = (index) => {
      const values = [...formData];
      values.splice(index, 1);
      setFormData(values);
      updateChartData(values);
    };
  
    const handleFormSubmit = (e) => {
      e.preventDefault();
      
      const dataset = formData.map((row) => ({
          
        x: parseFloat(row.x),
        st: parseFloat(row.st),
        y: parseFloat(row.y),
        cd: parseFloat(row.cd),
        qu: parseFloat(row.qu),
        al: parseFloat(row.al),
      }));
  
      setChartData({
        datasets: [
          {
            label: datasetLabel,
            data: dataset,
            backgroundColor: 'rgba(153,102,255,0.6)',
          },
        ],
      });
  
    
      
    };
  
    const updateChartData = (data) => {
      const dataset = data.map((row) => ({
        x: parseFloat(row.x),
        y: parseFloat(row.y),
      }));
  
      setChartData({
        datasets: [
          {
            label: datasetLabel,
            data: dataset,
            backgroundColor: 'rgba(153,102,255,0.6)',
          },
        ],
      });
    };
  
    const handleInputValueChange2 = (e) => {
      const value = e.target.value;
      innerdiameter = value - (2*inputValue1);
      outerperimeterval = pi * value;
      setInputValue2(value);
      setProduct(innerdiameter);
      setouterperimeter(outerperimeterval);
      setinnerperimeter(pi * innerdiameter);
      setareaout(0.25*pi*(value*value));
      setareapile(0.25*pi*(innerdiameter*innerdiameter))
  
    };
  
    const handleInputValueChange1 = (e) => {
      const value = e.target.value;
      innerdiameter = inputValue2 - (2*value); 
      setInputValue1(value);
      setProduct(innerdiameter);
      setouterperimeter(pi * inputValue2);
      setinnerperimeter(pi * (innerdiameter));
      setareapile(0.25*pi*(innerdiameter*innerdiameter))
    };
  
    const handleunitweight = (e) => {
      const value = e.target.value;
      setunitweight(value);
      
    };
  
    const handleconcretestrength = (e) => {
      const value = e.target.value;
      setconcretestrength(value);
      
    };
  
    const handleatmosphere = (e) => {
      const value = e.target.value;
      setatsmosphere(value);
      
    };
  
    const handleundrshearstrength = (e) => {
      const value = e.target.value;
      setundrshearstrength(value);
      
    };
  
    const handleallowqa = (e) => {
      const value = e.target.value;
      setallowqa(value);
      
    };
  
    // const handleresultcomp = (e) => {
    //   const value = e.target.value;
  
    //   setresultqacomp(78787879879);
      
    // };
  
    const handleallowqpa = (e) => {
      const value = e.target.value;
      setallowqpa(value);
      
    };
  
    const handlepanjangtiang = (e) => {
      const value = e.target.value;
      setpanjangtiang(value);
      
    };
  
      const handlekedalamangalian = (e) => {
      const value = e.target.value;
      setkedalamangalian(value);
      
    };
  
    const handlepoisson = (e) => {
      const value = e.target.value;
      setpoisson(value);
      
    };
  
    
  
  
    const [rows, setRows] = useState([{ col1: '', col2: '', sum: 0 }]);
  
    const handleRowChange2 = (index, event) => {
      const values = [...rows];
      values[index][event.target.name] = event.target.value;
      values[index].sum = parseFloat(values[index].col1 || 0) + parseFloat(values[index].col2 || 0);
      setRows(values);
    };
  
    const handleAddRow2 = () => {
      setRows([...rows, { col1: '', col2: '', sum: 0 }]);
    };
  
    const handleDeleteRow2 = (index) => {
      const values = [...rows];
      values.splice(index, 1);
      setRows(values);
    };
  
  
  
  
   
    const filteredData = formData.filter(row => parseFloat(row.y) !== '');
  
  
  
  
    //print pdf
    const generatePDF = () => {
      const doc = new jsPDF();
      doc.setFontSize(10);
      const pageWidth = doc.internal.pageSize.getWidth();
      const title = "AXIAL CAPACITY OF BORED PILE FOUNDATION";
      const subtitle = "DIVISI INFRASTRUKTUR 1";
      const textWidth = doc.getTextWidth(title);
      const subtitleWidth = doc.getTextWidth(subtitle);
      const x = (pageWidth - textWidth) / 2;
      const subtitleX = (pageWidth - subtitleWidth) / 2;
      doc.text(title, x, 10); // Judul dokumen PDF di tengah
      doc.text(subtitle, subtitleX, 15); // Subtitle di bawah judul
  
      // Mendapatkan data tabel untuk PDF
      const tableColumn = ["Depth", "Code", "N-SPT/RQD", "NSPT/RQD avg.", "cu/Qu"];
      const tableRows = [];
  
      filteredData.forEach((item) => {
        
        const rowData = [item.y, item.cd, item.st, item.x, cuperqulist[counterpdf]];
        counterpdf = counterpdf + 1;
        tableRows.push(rowData);
      });
  
      // Menambahkan tabel ke PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
        headStyles: { fontSize: 8, fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Mengatur ukuran font untuk header tabel, background putih, dan teks hitam
        bodyStyles: { fontSize: 8, fillColor: [255, 255, 255] }, // Mengatur ukuran font untuk isi tabel dan background putih
        styles: { cellPadding: 1, lineWidth: 0.1, lineColor: [0, 0, 0] }, // Mengatur spasi antar baris pada tabel dan border
      });
  
      // Menyimpan file PDF
      doc.save("table_data.pdf");
    };
  
    return (
  
  
  
  
  
  
  
  
      <section className="card">
      <div className="container">
        <div className="">
          <p className=""><b><u>Soil Data Input</u></b></p>
         
  
        <div>
     
        <input type='file' onChange={handleFileUpload} />
        <form onChange={handleFormSubmit}>
          <input
            type="text"
            value={datasetLabel}
            onChange={(e) => setDatasetLabel(e.target.value)}
            placeholder="Nama Label Dataset"
          />
             <div>
              <table>
                <tr>
                    <td style={{ width: '85px' }}>
                        Depth
                    </td>
                    <td>
                        Soil Type
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>

                </tr>
              </table>

            </div>
          {formData.map((input, index) => (
            
            <div key={index}>
              <input
                type="number"
                name="y"
                value={input.y}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Depth"
                style={{ width: '85px', height: '21px' }}
              />
              <input
                type="text"
                name="st"
                value={input.st} 
                onChange={(event) => handleInputChange(index, event)}
                placeholder="soil type"
                style={{ width: '85px', height: '21px' }}
              />
              <input
                type="number"
                name="x"
                value={input.x}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="X value"
                style={{ width: '85px', height: '21px' }}
              />
              <input
                type="text"
                name="cd"
                value={input.cd}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="code value"
                style={{ width: '85px', height: '21px' }}
              />
              <input
                type="number"
                name="qu"
                value={input.qu}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="qu value"
                style={{ width: '85px', height: '21px' }}
              />
              <input
                type="number"
                name="em"
                value={input.em}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="em value"
                style={{ width: '85px', height: '21px' }}
              />
              <input
                type="number"
                name="al"
                value={input.al}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="al value"
                style={{ width: '85px', height: '21px' }}
              />
          
               
                  &nbsp;&nbsp;<FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteRow(index)} style={{ color: 'gray' }}/>
              
           
            </div>
          ))}
  
        <FontAwesomeIcon icon={faAdd} onClick={handleAddRow} style={{ color: 'blue' }}/>
        </form>
        <Scatter data={chartData} />
  
        <br>
            
        </br>
        <br>
                
        </br>   
        
        <table className='text-sm'>
          <tr>
              <td>
                  <table>
                      <tr>
                          <td>
                              <b><u>Pile Properties</u></b>
                          </td>
                          <td>
  
                          </td>
                          <td>
  
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Type
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                              Bored Pile
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Outer Diameter
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                              <input
                                  type="number"
                                  value={inputValue2}
                                  onChange={handleInputValueChange2} 
                                  style={{ width: '75px' }}
                              /> m
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Thickness
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                              <input
                                  type="number"
                                  value={inputValue1}
                                  onChange={handleInputValueChange1}
                                  style={{ width: '75px' }}
                
                              /> m
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Inner Diameter
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                              <label>{Number(product).toFixed(2)} m</label>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Outer Perimeter
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                            <label>{Number(outerperimeter).toFixed(2)} m</label>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Inner Perimeter
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                            <label>{Number(innerperimeter).toFixed(2)} m</label>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Area out
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                            <label>{Number(areaout).toFixed(2)} m²</label>
                          </td>
                      </tr>
                      <tr>
                          <td>
                            Area pile
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                            <label>{Number(areapile).toFixed(2)} m²</label>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Unit Weight
                          </td>
                          <td>
                              :
                          </td>
                     
                          <td>
                          <input
                                  type="number"
                                  value={unitweight}
                                  onChange={handleunitweight} 
                                  style={{ width: '75px' }}
                              /> kN/m3
                          </td>
                         
                      </tr>
                      <tr>
                          <td>
                              Concrete Strength
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                            <input
                                  type="number"
                                  value={concretestrength}
                                  onChange={handleconcretestrength} 
                                  style={{ width: '75px' }}
                            /> kN/m²
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Atmosphere press.
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                            <input
                                  type="number"
                                  value={atmosphere}
                                  onChange={handleatmosphere} 
                                  style={{ width: '75px' }}
                            /> kN/m²
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <b><u>Soil Type</u></b>
                          </td>
                          <td>
                              
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
                              S
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                              Sand Layer
                          </td>
                      </tr>
                      <tr>
                          <td>
                              C
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                              Clay Layer
                          </td>
                      </tr>
                      <tr>
                          <td>
                              R
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                              Rock Layer
                          </td>
                      </tr>
                     
  
                  </table>
              </td>
              <td valign='top'>
                  <table>
                      <tr>
                          <td>
                              <b><u>Calc. Method</u></b>
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                              Based on N-SPT (AASHTO)
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Undr. Shear Strength
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                              <input
                                  type="number"
                                  value={undrshearstrength}
                                  onChange={handleundrshearstrength} 
                                  style={{ width: '75px' }}
                              /> *NSPT
                          </td>
                          <td>
                              
                          </td>
                      </tr>
            
                      <tr>
                          <td>
                              <b><u>Compression</u></b>
                          </td>
                          <td>
                              
                          </td>
                          <td>
                              
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
                            Skin Friction (Qs)
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            α*cu*perimeter*l
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
  
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            2*N-SPT*perimeter*l
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
  
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            qsr*perimeter*l
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
                            End Bearing (Qp)
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            9*cu*area
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
                            
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            70*N-SPTav
                          </td>
                          <td>
  
                          </td>
                      </tr>
                      <tr>
                          <td>
                            
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            2.5*qu*area
                          </td>
                          <td>
                            
                          </td>
                      </tr>
                      <tr>
                          <td>
                            Ultimate (Qu)
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            Qs + Qp
                          </td>
                          <td>
                            
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <b><u>Pull out</u></b>
                          </td>
                          <td>
                              
                          </td>
                          <td>
                              
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
                            Skin Friction (Qs)
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            ϕ*Qs
                          </td>
                          <td>
                            (AASHTO)
                          </td>
                      </tr>
                      <tr>
                          <td>
                            Pile Weight (Wp)
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            Area pile*Unit Weight of Pile *l
                          </td>
                          <td>
                            
                          </td>
                      </tr>
                      <tr>
                          <td>
                            Ultimate (Qpu)
                          </td>
                          <td>
                              =
                          </td>
                          <td>
                            Qs + Wp
                          </td>
                          <td>
                            
                          </td>
                      </tr>
                  </table>
              </td>
              <td valign='top'>
                <table>
                 <tr>
                          <td>
                              <b><u>Allowable</u></b>
                          </td>
                          <td>
                              
                          </td>
                          <td>
                              
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Qa
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                               <input
                                type="number"
                                value={allowqa}
                                onChange={handleallowqa} 
                                style={{ width: '70px' }}
                              />
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Qpa
                          </td>
                          <td>
                              :
                          </td>
                          <td>
                               <input
                                type="number"
                                value={allowqpa}
                                onChange={handleallowqpa} 
                                style={{ width: '70px' }}
                              />
                          </td>
                      </tr>
                  <tr>
                    <td>
                      <b><u>Requirement</u></b>
                    </td>
                    <td>
                      
                    </td>
                    <td>
                      
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Panjang Tiang
                    </td>
                    <td>
                      :  
                    </td>
                    <td>
                      <input
                                type="number"
                                value={panjangtiang}
                                onChange={handlepanjangtiang} 
                                style={{ width: '70px' }}
                              /> m
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kedalaman Galian
                    </td>
                    <td>
                      :  
                    </td>
                    <td>
                      <input
                                type="number"
                                value={kedalamangalian}
                                onChange={handlekedalamangalian} 
                                style={{ width: '70px' }}
                              /> m
                    </td>
                  </tr>
  
                </table>
              </td>
          </tr>
        </table>  
        <br>
            
        </br>
        <br>
            
        </br>
        <br>
          
        </br>
        <p className="text-sm"><b>AXIAL CAPACITY</b></p>  
        <br>
          
        </br>    
        <table className='text-sm ymr-table ymr-white ymr-bordered ymr-striped ymr-table-semuasisi ymr-table-semuahead ymr-table-all'>
          <thead>
            <tr>
              <th rowSpan={4}>Depth<br></br>(m)</th>
              <th colSpan={5} rowSpan={2}>Soil Properties</th>
              <th colSpan={8}>Ultimate</th>
              <th colSpan={2}>Allowable</th>
            </tr>
            <tr>          
              <th colSpan={4}>Compression Capacity (kN)</th>
              <th colSpan={4}>Pull Out Capacity (kN)</th>
              <th>
                {allowqa}
              </th>
              <th>
  
         
                {allowqpa}
  
      
  
              </th>
              
            </tr>          
            <tr>
  
            
              <th rowSpan={2}>Code</th>
              <th rowSpan={2}>N-SPT/RQD</th>
              <th rowSpan={2}>NSPT/RQD avg.</th>
              <th rowSpan={2}>cu/Qu</th>
              <th rowSpan={2}>α</th>            
              <th colSpan={2}>Outer Friction</th>
              <th rowSpan={2}>End Bearing</th>
              <th rowSpan={2}>Qu</th>
              <th colSpan={2}>Friction*</th>
              <th rowSpan={2}>Wp</th>
              <th rowSpan={2}>Qpu</th>
              <th>Qa (kN)</th>
              <th>Qpa (kN)</th>
            
            </tr>
  
            <tr>
              <th>Local</th>  
              <th>Cumm.</th>
              <th>Local</th>  
              <th>Cumm.</th>
              <th>Compress</th>
              <th>Pull Out</th>
            </tr>
          </thead>
          <tbody className='ymr-text-center'>
  
            {
              
              filteredData.map((row, index) => (
            
              sby[index] = 0 - kedalamangalian <= row.y ? 0 : row.y, 
              sby3 = 0 - kedalamangalian <= row.y ? 0 : row.x,
  
              //alpha = (row.cd === 'C' || row.cd === 'M'? (undrshearstrength * sby2[index]) > 150 && (undrshearstrength * sby2[index]) < 250? 0.55-(0.1*((undrshearstrength * sby2[index])/100-1.5)): (undrshearstrength * sby2[index]) <= 150 ? 0.55 : 0.5 : row.cd === 'R'? row.al: '-'),
              alpha = 0 - kedalamangalian <= row.y ? 0 : (row.cd === 'C' || row.cd === 'M'? (undrshearstrength * sby3) > 150 && (undrshearstrength *sby3) < 250? 0.55-(0.1*((undrshearstrength * sby3)/100-1.5)): (undrshearstrength * sby3) <= 150 ? 0.55 : 0.5 : row.cd === 'R'? row.al: '-'),
  
              //cuperqu = (row.cd === 'C'? (undrshearstrength * row.x): row.cd === 'R'? row.qu : 0),
              cuperqu = 0 - kedalamangalian <= row.y ? 0 : (row.cd === 'C'? (undrshearstrength * row.x): row.cd === 'R'? row.qu : 0), 
              //ofl = (row.cd==='S'? 2*sby2[index]*outerperimeter*Math.abs(sby[index] - deptsebelumnya) : row.cd === 'C'? cuperqu*alpha*outerperimeter*Math.abs(sby[index] - deptsebelumnya): 0.65*alpha*atmosphere*Math.sqrt(cuperqu/atmosphere) < 7.8*atmosphere*Math.sqrt(concretestrength/atmosphere)? outerperimeter*Math.abs(sby[index] - deptsebelumnya)*0.65*alpha*atmosphere*Math.sqrt(cuperqu/atmosphere):outerperimeter*Math.abs(sby[index] - deptsebelumnya) * 7.8*atmosphere*Math.sqrt(concretestrength/atmosphere)),
              cuperqulist[index] = cuperqu,
              ofl = 0 - kedalamangalian <= row.y ? 0 : (row.cd==='S'? 2*sby3*outerperimeter*Math.abs(sby[index] - deptsebelumnya) : row.cd === 'C'? cuperqu*alpha*outerperimeter*Math.abs(sby[index] - deptsebelumnya): 0.65*alpha*atmosphere*Math.sqrt(cuperqu/atmosphere) < 7.8*atmosphere*Math.sqrt(concretestrength/atmosphere)? outerperimeter*Math.abs(sby[index] - deptsebelumnya)*0.65*alpha*atmosphere*Math.sqrt(cuperqu/atmosphere):outerperimeter*Math.abs(sby[index] - deptsebelumnya) * 7.8*atmosphere*Math.sqrt(concretestrength/atmosphere)),
              
              cumofl = 0 - kedalamangalian <= row.y ? 0 : cumofl + ofl,     
              
              deptsebelumnya = row.y,  
              //deptsebelumnya = sby[index],
              enbe[counter]  = row.y,            
              //enbe[counter] = sby[index],
  
              counter++,
              //=IF(LEFT(D37,1)="S",MIN(70*F37,4000)*$F$20,IF(LEFT(D37,1)="C",MIN(9*G37,4000)*$F$20,2.5*G37*$F$20))
              //enber = (row.cd==='S'? 70*sby2[index] < 4000? 70*sby2[index]*areapile: 4000*areapile : row.cd === 'C'? 9*cuperqu < 4000? 9*cuperqu*areapile: 4000*areapile : 2.5*cuperqu*areapile),
              enber = - kedalamangalian <= row.y ? 0 : (row.cd==='S'? 70*sby3 < 4000? 70*sby3*areapile: 4000*areapile : row.cd === 'C'? 9*cuperqu < 4000? 9*cuperqu*areapile: 4000*areapile : 2.5*cuperqu*areapile),
              //quvalue = cumofl + enber,
              quvalue = - kedalamangalian <= row.y ? 0 : cumofl + enber,
              //frilo = ofl * 0.7,
              frilo = - kedalamangalian <= row.y ? 0 : ofl * 0.7,   
              //frilosebelum = frilosebelum + frilo,
              frilosebelum = - kedalamangalian <= row.y ? 0 :  frilosebelum + frilo,
              //=$F$21*$F$20*ABS(C42)
              //wepe = areapile*unitweight*Math.abs(sby[index]),
              wepe = - kedalamangalian <= row.y ? 0 :  areapile*unitweight*Math.abs(sby[index]),
              //qipu = frilosebelum + wepe,
              qipu = - kedalamangalian <= row.y ? 0 :  frilosebelum + wepe,
              //qacom = quvalue / allowqa,
              qacom  = - kedalamangalian <= row.y ? 0 : quvalue / allowqa,
              //qpull = qipu / allowqpa,
              qpull = - kedalamangalian <= row.y ? 0 : qipu / allowqpa, 
              sbx[index] = cumofl,
              sbxultcomp[index] = enber,
              sbxultcomp2[index] = quvalue,
              sbxultpullout[index] = frilosebelum,
              sbxultpullout2[index] = wepe,
              sbxultpullout3[index] = qipu,
  
              bgcolor = 0 - panjangtiang - kedalamangalian === row.y ? bgcolor = 'lightblue' :  bgcolor = '',
  
  
              <tr key={index}>
                <td style={{ backgroundColor: bgcolor }}>{row.y}</td>              
                <td style={{ backgroundColor: bgcolor }}>{row.cd}</td>
                <td style={{ backgroundColor: bgcolor }}>{row.x}</td>
                <td style={{ backgroundColor: bgcolor }}>{row.x}</td>
                <td style={{ backgroundColor: bgcolor }}>{cuperqu}</td>
                <td style={{ backgroundColor: bgcolor }}>{alpha === '-' ? '-':Number(alpha).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(ofl).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(cumofl).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(enber).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(quvalue).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(frilo).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(frilosebelum).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(wepe).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(qipu).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(qacom).toFixed(2)}</td>
                <td style={{ backgroundColor: bgcolor }}>{Number(qpull).toFixed(2)}</td>             
              </tr>
  
              ))}
            </tbody>
            </table>
            <br>
              
            </br>
            <br>
            
            </br>
            <br>
                
            </br>
            <p className="text-sm"><b>LATERAL CAPACITY</b></p>
            <br>
  
            </br>  
            <div>
              <table className='text-sm ymr-table ymr-white ymr-bordered ymr-table-semuasisi ymr-table-semuahead ymr-table-all'>
              <thead>
                <tr>
                <th colSpan={2}>Elevation</th>
                
                <th rowspan={2}>Depth</th>
                <th colSpan={2}>Description</th>
                
                <th rowspan={2}>Category</th>
                <th rowspan={2}>N SPT</th>
                
                <th rowspan={2}>ɣsat (kN/m3)</th>
                <th rowspan={2}>ɣ' (kN/m3)</th>
                <th rowspan={2}>c (kN/m2)</th>
                <th rowspan={2}>ɸ (kN/m2)</th>
                
                <th rowspan={2}>k</th>
                <th rowspan={2}>E50</th>
                <th rowspan={2}></th>
                </tr>
                <tr>
                <th>Top</th>
                <th>Bottom</th>
                <th>Code</th>
                <th>Type</th>
             
               
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  //=IF(G17="C",IF(J17<2,"Very Soft",IF(J17<=5,"Soft",IF(J17<=10,"Medium",IF(J17<=20,"Stiff",IF(J17<=30,"Very Stiff",IF(J17>30,"Hard")))))),IF(G17="S",IF(J17<4,"Very Loose",IF(J17<10,"Loose",IF(J17<30,"Medium",IF(J17<50,"Dense",IF(J17>=50,"Very Dense")))))))              
                  cate = (row.col3 === 'C'? row.col6 < 2? 'Very Soft': row.col6 <= 5? 'Soft': row.col6 <= 10? 'Medium': row.col6 <= 20? 'Stiff': row.col6 <= 30? 'Very Stiff': row.col6 > 30? 'Hard': '-': row.col3 === 'S'? row.col6 < 4? 'Very Loose': row.col6 < 10? 'Loose': row.col6 < 30? 'Medium': row.col6 < 50? 'Dense': row.col6 >= 50? 'Very Dense': '-': '-'),
                  ysat = row.col3 === 'C'? row.col6 < 10? 16 : row.col6 <= 20? 16.5 : row.col6 <= 30? 17: row.col6 <= 50? 18: 19 : row.col3 === 'S'? row.col6 < 10? 17 : row.col6 <= 20? 17.5 : row.col6 <= 30? 18: row.col6 <= 50? 19: 20: 0,
                  yak = ysat - 10,
                  //=IF(G19="C",6*J19,1)
                  ckn = row.col3 === 'C'? 6*row.col6: 1,
                  //=IF(G17="C",1,SQRT(12*J17)+15)
                  okn = row.col3 === 'C'? 1: Math.sqrt(12*row.col6)+15,
                  //=IF(G17="C",IF(I17="Very Soft",8140,IF(I17="Soft",8140,IF(I17="Medium",27150,IF(I17="Stiff",136000,IF(I17="Very Stiff",271000,IF(I17="Hard",543000,0)))))),IF(I17="Very Loose",5430,IF(I17="Loose",5430,IF(I17="Medium",16300,IF(I17="Dense",33900,IF(I17="Very Dense",33900,0))))))
                  kvalue = row.col3 === 'C'? cate === 'Very Soft'? 8140: cate === 'Soft'? 8140: cate === 'Medium'? 27150: cate === 'Stiff'? 136000: cate === 'Very Stiff'? 271000: cate === 'Hard'? 543000: 0: row.col3 === 'S'? cate === 'Very Loose'? 5430: cate === 'Loose'? 5430: cate === 'Medium'? 16300: cate === 'Dense'? 33900: cate === 'Very Dense'? 33900: 0: 0,
                  //=IF(G17="C",IF(I17="Very Soft",0.02,IF(I17="Soft",0.02,IF(I17="Medium",0.01,IF(I17="Stiff",0.007,IF(I17="Very Stiff",0.005,IF(I17="Hard",0.004,0)))))),"-")
                  e50 = row.col3 === 'C'? cate === 'Very Soft'? 0.02: cate === 'Soft'? 0.02: cate === 'Medium'? 0.01: cate === 'Stiff'? 0.007: cate === 'Very Stiff'? 0.005: cate === 'Hard'? 0.004: 0: '-',
                  //=4700*SQRT(H45)*1000
                  //=H46/(2*(1+H47))
                  my = Number(4700*Math.sqrt(concretestrength/1000)*1000).toFixed(2),
                  ppn = (poisson * 1),
                  <tr key={index}>
                    <td>
                      <input
                        type="number"
                        name="col1"
                        value={row.col1}
                        onChange={(event) => handleRowChange2(index, event)}
                        style={{ width: '70px' }}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="col2"
                        value={row.col2}
                        onChange={(event) => handleRowChange2(index, event)}
                        style={{ width: '70px' }}
                      />
                    </td>
                    <td>
                      {row.col2 - row.col1}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="col3"
                        value={row.col3}
                        onChange={(event) => handleRowChange2(index, event)}
                        style={{ width: '70px' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="col4"
                        value={row.col4}
                        onChange={(event) => handleRowChange2(index, event)}
                        style={{ width: '70px' }}
                      />
                    </td>
                    <td>
                      {cate}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="col6"
                        value={row.col6}
                        onChange={(event) => handleRowChange2(index, event)}
                        style={{ width: '70px' }}
                      />
                    </td>
                    <td>
                      {ysat}
                    </td>
                    <td>
                      {yak}
                    </td>
                    <td>
                      {ckn}
                    </td>
                    <td>
                      {Number(okn).toFixed(2)}
                      
                    </td>
                    <td>
                      {kvalue}
                    </td>
                    <td>
                      {e50}
                    </td>
                    <td>
                      <button onClick={() => handleDeleteRow2(index)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={handleAddRow2}>Add Row</button>
            <br>
  
            </br>
            <br>
              
            </br>
            <br>
  
            </br>
            <p className="text-sm"><b><u>Pile Properties</u></b></p>
            <br>
  
            </br>
  
            <table className='text-sm'>
                <tr>
                  <td>
                    Type
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    Bored Pile
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Outer Diameter
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {inputValue2}
                    &nbsp;m
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Thickness
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {inputValue1}
                    &nbsp;m
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Inner Diameter
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {inputValue2 - (2*inputValue1)}
                    &nbsp;m
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Outer Perimeter
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {Number(pi * inputValue2).toFixed(3)}
                    &nbsp;m
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Inner Perimeter
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {Number(pi * (inputValue2 - (2*inputValue1))).toFixed(3)}
                    &nbsp;m
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Areaout
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    <label>{Number(areaout).toFixed(3)} m²</label>
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Areapile
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    <label>{Number(areapile).toFixed(3)} m²</label>
                  </td>
  
                </tr>
                <tr>
                  <td>  
                    Mom of Inertia
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    <label>{Number(0.015625*pi*(inputValue2*inputValue2*inputValue2*inputValue2)).toFixed(6)} m⁴</label>
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Unit Weight
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {unitweight} kN/m3
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Concrete Strength
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {concretestrength/1000} MPa
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Modulus Young
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {my} kN/m²
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Poisson ratio
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    <input
                      type="number"
                      value={poisson}
                      onChange={handlepoisson} 
                      style={{ width: '75px' }}
                    /> 
                  </td>
  
                </tr>
                <tr>
                  <td>
                    Shear Modulus
                  </td>
                  <td>
                    :
                  </td>
                  <td>
                    {Number(my/(2*(1+ppn))).toFixed(2)} kN/m²
                  </td>
  
                </tr>
  
  
            </table>
  
            <br></br>
            <br></br>
            <table className='ymr-table'>
              <tr>
                <td>
                  <Line
                      data={{
                        datasets: [
                          {
                            label: 'Data',
                            data: Array.from({ length: counter}, (_, i) => ({
                              x: sbx[i],
                              y: sby[i],
                            })),
                  
                            backgroundColor: 'rgba(255,99,132,0.6)',
                          },
                        ],
                      }}
                      height="750px"
                      width="200px"
  
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          type: 'linear',
                          position: 'bottom',
                        },
                      },
                    }}
                  />
                </td>
                <td>
                <Line
                      data={{
                        datasets: [
                          {
                            label: 'Outter Friction',
                            data: Array.from({ length: counter}, (_, i) => ({
                              x: sbx[i],
                              y: sby[i],
                            })),
                  
                            backgroundColor: 'rgba(255,99,132,0.6)',
                          },
                              {
                            label: 'End Bearing',
                            data: Array.from({ length: counter}, (_, i) => ({
                              x: sbxultcomp[i],
                              y: sby[i],
                            })),
                            borderColor: 'rgba(193,92,105,1)',
                          },
                                                      {
                            label: 'Qu',
                            data: Array.from({ length: counter}, (_, i) => ({
                              x: sbxultcomp2[i],
                               y: sby[i],
                            })),
                            borderColor: 'rgba(222,152,155,1)',
                          },
                        ],
                      }}
                      height="750px"
                      width="300px"
  
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          type: 'linear',
                          position: 'bottom',
                        },
                      },
                    }}
                  />
                </td>
                <td>
                <Line
                      data={{
                        datasets: [
                          {
                            label: 'Friction*',
                            data: Array.from({ length: counter}, (_, i) => ({
                              x: sbxultpullout[i],
                              y: sby[i],
                            })),
                  
                            backgroundColor: 'rgba(255,99,132,0.6)',
                          },
                          {
                            label: 'Wp',
                            data: Array.from({ length: counter}, (_, i) => ({
                              x: sbxultpullout2[i],
                              y: sby[i],
                            })),
                            borderColor: 'rgba(153,92,55,1)',
                          },
                          {
                            label: 'Qpu',
                            data: Array.from({ length: counter}, (_, i) => ({
                              x: sbxultpullout3[i],
                              y: sby[i],
                            })),
                            borderColor: 'rgba(222,152,155,1)',
                          },
                        ],
                      }}
                      height="750px"
                      width="350px"
  
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          type: 'linear',
                          position: 'bottom',
                        },
                      },
                    }}
                  />
                </td>
              </tr>
            </table>
           
          </div>
  
  
      </div>
  
  
      </div>
        
      </div>
      <button onClick={generatePDF}>Make Report</button>
  
  
  
  
  
  
  
  
  
    </section>
  
  
  
    );
  
  
  
  };
  
  export default Profile;

